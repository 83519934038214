.Blog {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: auto;
    padding-top: 0px;
    padding-bottom: 40px;
    .BlogHeader {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        // position: relative;

        .BlogHeaderTop {
            display: flex;
            flex-direction: column;
            width: 100%;
            z-index: 1;
            background-color: #2a1768;
            padding: 130px 5%;

            h1 {
                font-size: 40px;
                font-weight: 600;
                color: #ffffff;
            }
            h3 {
                color: #ffffff;
                font-size: 23px;
                font-weight: 300;
                margin-top: 30px;
            }
            h5 {
                font-size: 25px;
                font-weight: 400;
                color: white;
                text-decoration: unset;
                width: 100%;
                margin-top: 30px;
                span {
                    font-size: 20px;
                    font-weight: 300;
                }
            }
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 17px;
                font-weight: 500;
                color: WHITE;
                cursor: pointer;
                background-color: #DA291C;
                border-radius: 3px;
                cursor: pointer;
                padding: 10px;
                padding-left: 20px;
                margin-right: 0px;
                width: 250px;
                margin-top: 20px;

                img {
                    // background-color: white;
                    height: 20px;
                    width: 20px;
                    margin-left: 10px;
                    filter: invert(1);
                }
            }
            a:last-child{
                background-color: hsl(254, 84%, 56%);
            }

            .FaqMainBannerCategories {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                height: auto;
                padding-top: 10px;
                a {
                    white-space: nowrap;
                    margin: 10px 10px 0px 0px;
                    font-size: 18px;
                    font-weight: 400;
                    color: white;
                    text-decoration: unset;
                    border: 1px solid white;
                    padding: 5px 8px;
                    border-radius: 5px;
                }
                a:hover {
                    text-decoration: underline;
                }
            }
        }
        .BlogHeaderBottom {
            padding: 30px 5%;
            border-bottom: 1px solid #4b657726;
            h1 {
                font-size: 40px;
                font-weight: 500;
                color: #000000;
            }
        }
    }
    .BlogsList {
        padding: 30px 5%;
        width: 100%;
        .BlogsListBox {
            article {
                display: flex;
                // flex-direction: column;
                width: 100%;
            }
            display: flex;
            width: 100%;
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #4b657726;
            cursor: pointer;
            text-decoration: unset;
            .BlogsListBoxLeft {
                width: 35%;
                max-width: 300px;
                max-height: 200px;
                min-height: 200px;

                img {
                    max-width: 300px;
                    min-width: 300px;
                    max-height: 200px;
                    min-height: 200px;

                    // width: 100%;
                    object-fit: cover;
                    transition: all 0.5s ease-in-out;
                    border-radius: 0%;
                }
            }
            .BlogsListBoxRight {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                width: 65%;
                position: relative;
                padding-left: 60px;
                h1 {
                    font-size: 25px;
                    font-weight: 600;
                    color: #0f212e;
                }
                h2 {
                    margin-top: 30px;
                    color: #4f6473;
                    font-size: 14px;
                    font-weight: 400;
                }
                span {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 30px;
                    width: 100%;
                    h5 {
                        color: #000000;
                        font-size: 15px;
                        font-weight: 500;
                    }
                    h4 {
                        color: #4f6473;
                        font-size: 13px;
                        font-weight: 300;
                    }
                }
                img {
                    width: 40px;
                    transform: rotate(0deg);
                    position: absolute;
                    bottom: 0px;
                    right: 0px;
                }
            }
        }
        .BlogsListBox:hover {
            position: relative;
            .BlogsListBoxLeft {
                img {
                    transform: scale(1.05);
                }
            }
            .BlogsListBoxMiddle {
                h3 {
                    position: absolute;
                    left: 10px;
                    top: 10px;
                    color: white;
                }
            }
            .BlogsListBoxRight {
                h2 {
                    color: #0f212e;
                }
                img {
                    transform: scale(1.05);
                }
            }
        }
    }
    // Blog and Faq
    .BlogsListFaq {
        display: flex;
        flex-direction: column;
        padding: 30px 5% 60px 5%;
        width: 100%;
        .BlogsListFaqHead {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #4b657726;
            padding: 20px 0px;
            h1 {
                font-size: 25px;
                font-weight: 500;
                color: #2a1768;
            }
            a {
                font-size: 17px;
                font-weight: 400;
                color: #2a1768;
            }
            a:hover {
                text-decoration: underline;
            }
        }
        .BlogsListFaqList {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            .FaqListBox {
                padding: 15px 20px;
                border-left: 3px solid #2a1768;
                margin-top: 25px;
                cursor: pointer;
                a {
                    text-decoration: unset;
                    width: 100%;
                    height: 100%;
                    h1 {
                        font-size: 1.2rem;
                        font-weight: 600;
                        color: #151515;
                    }
                    h2 {
                        font-size: 16px;
                        font-weight: 300;
                        color: #6e6f81;
                        margin-top: 5px;
                    }
                    h3 {
                        font-size: 14px;
                        font-weight: 500;
                        color: #2a1768;
                        margin-top: 5px;
                        margin-top: 10px;
                        span {
                            padding: 3px 5px;
                            border-left: 2px solid #2a1768;
                            width: auto;
                        }
                    }
                }
            }
            .FaqListBox:hover {
                transform: scale(1.03);
                transition: 0.5s;
            }
        }
    }
}
@media (max-width: 850px) {
    .Blog {
        padding: 0px 0% 30px 0%;
        .BlogHeader {
            flex-direction: column;
            padding: 0px 0% 00px 0%;
            width: 100%;
            .BlogHeaderTop {
                width: 100%;
                padding: 70px 5%;
                h1 {
                    font-size: 30px;
                }
                h3 {
                    font-size: 16px;
                }
            }
            .BlogHeaderBottom {
                padding: 30px 5%;
                // background-color: red;
                h1 {
                    font-size: 25px;
                }
            }
        }
        .BlogsList {
            .BlogsListBox {
                article {
                    // display: flex;
                    flex-direction: column;
                    // width: 100%;
                }
                flex-direction: column;
                width: 100%;
                .BlogsListBoxLeft {
                    width: 100%;
                    max-width: unset;
                    max-height: 200px;
                    min-height: 200px;
                    img {
                        max-width: 100%;
                        min-width: 100%;
                        max-height: 200px;
                        min-height: 200px;
                    }
                }
                .BlogsListBoxRight {
                    width: 100%;
                    padding-left: 0px;

                    h1 {
                        font-size: 15px;
                        font-weight: 500;
                        color: #0f212e;
                        margin-top: 15px;
                    }
                    h2 {
                        margin-top: 12px;
                        color: #4f6473;
                        font-size: 13px;
                        font-weight: 300;
                        margin-bottom: 30px;
                    }
                    span {
                        margin-top: 0px;
                        width: 100%;
                        h5 {
                            color: #000000;
                            font-size: 15px;
                            font-weight: 500;
                        }
                        h4 {
                            color: #4f6473;
                            font-size: 13px;
                            font-weight: 300;
                        }
                    }
                }
            }
            .BlogsListBox:hover {
                position: relative;
                .BlogsListBoxLeft {
                    img {
                        transform: scale(1);
                    }
                }
                .BlogsListBoxMiddle {
                    h3 {
                        position: unset;
                        color: black;
                    }
                }
                .BlogsListBoxRight {
                    h2 {
                        color: #0f212e;
                    }
                    img {
                        transform: scale(1.05);
                    }
                }
            }
        }
        .BlogsListFaq {
            .BlogsListFaqHead {
                align-items: start;
                flex-direction: column;
                h1 {
                    margin-bottom: 10px;
                }
            }
        }
        .BlogApply {
            .BlogApplyBox {
                width: 100%;
                padding: 40px 20px;

                h2 {
                    width: 100%;
                    font-size: 17px;
                }
            }
        }
    }
}
@media screen and (min-width: 1700px) {
    .Blog {
        .BlogHeader {
            .BlogHeaderTop,
            .BlogHeaderBottom {
                padding-right: 13%;
                padding-left: 13%;
            }
        }
        .BlogsList,
        .BlogsListFaq {
            padding-right: 13%;
            padding-left: 13%;
        }
        .BlogApply {
            padding-right: 13%;
            padding-left: 13%;
        }
    }
}
