.PopUp {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.436);
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;backdrop-filter: blur(2px);
  z-index: 20;
  .MainBanner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: row-reverse;
    min-height: 600px;
    min-width: 80%;
    max-height: 65vh;
    background-color: #eceef6;
    padding: 100px 5% 0px 5%;
    overflow: hidden;
    border-radius: 10px;
    .MainBannerHeader {
      position: absolute;
      right: 5%;
      top: 5%;
      img {
        width: 30px;
        cursor: pointer;
      }
    }
    .MainBannerLeft {
      display: flex;
      ruby-align: center;
      flex-direction: column;
      width: 50%;
      padding-left: 30px;
      margin-top: -100px;
      h1 {
        font-size: 30px;
        font-weight: 700;
        color: #2a1768;
      }
      h2 {
        font-size: 25px;
        font-weight: 400;
        color: #2a1768;
        margin-top: 15px;
      }
      a {
        align-items: center;
        justify-content: space-between;
        font-size: 32px;
        font-weight: 700;
        color: #da291c;
        cursor: pointer;
        border-radius: 3px;
        cursor: pointer;
        margin-top: 20px;
      }
      a:hover {
        text-decoration: underline;
      }
      a:last-child {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 300px;
        background-color: #2a1768;
        color: white;
        font-size: 25px;
        padding: 18px 0px;
        font-weight: 500;

        img {
          height: 25px;
          width: 25px;
          margin-left: 10px;
          filter: invert(1);
        }
      }
    }
    .MainBannerRight {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: 50%;
      height: 100%;
      position: relative;
      img {
        position: absolute;
        bottom: -250px;
        margin-top: 100px;
        width: 500px;
        z-index: 2;
        border-radius: 0px;
      }
      .MainBannerRightBubble1 {
        // display: none;
        position: absolute;
        right: 290px;
        bottom: -200px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #ffffff;
        opacity: 0.8;
        z-index: 3;
        box-shadow: 0px 10px 15px -3px rgba(255, 255, 255, 0.54);
      }
      .MainBannerRightBubble2 {
        // display: none;
        position: absolute;
        right: 50px;
        bottom: -150px;
        width: 90px;
        height: 90px;
        border-radius: 50%;
        opacity: 0.5;
        background-color: #ffffff;
        z-index: 3;
        box-shadow: 0px 10px 15px -3px rgba(255, 255, 255, 0.54);
      }
    }
  }
}

@media (max-width: 790px) {
  .PopUp {
  align-items: flex-start;
  padding: 20px 5%;
    .MainBanner {
      flex-direction: column;
      padding: 200px 5% 20px 5%;
      // max-height: 100vh;
      min-width: 100%;
      max-height: 100%;
      height: 100%;

      .MainBannerLeft {
        width: 100%;
        height: 50%;
        margin-top: -180px;
        padding-right: 0px;
        padding-left: 0px;
        h1 {
          font-size: 30px;
        }
        h2 {
          font-size: 20px;
        }
        a {
          // background-color: white;
        }
      }
      .MainBannerRight {
        width: 100%;
        height: 100%;
        margin-top: 100px;
        img {
          position: absolute;
          bottom: -250px;
          // margin-top: 140px;
          width: 320px;
          z-index: 2;
        }
        .MainBannerRightBubble1 {
          right: 190px;
          bottom: -220px;
          width: 120px;
          height: 120px;
        }
        .MainBannerRightBubble2 {
          right: 50px;
          bottom: -130px;
          width: 90px;
          height: 90px;
        }
      }
    }
  }
}
@media screen and (min-width: 1700px) {
  .PopUp {
    padding: 20px 13%;
  }
}
