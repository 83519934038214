.Header {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: sticky;
  top: 0;
  height: auto;
  backdrop-filter: blur(40px);
  z-index: 5;
  border-bottom: 1px solid #e9e9e99c;
  .HeaderTopComputer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #2a1768;
    width: 100%;
    height: 80px;
    padding: 30px 5%;
    box-shadow: 0px 10px 15px -3px #0000001a;
    z-index: 1;
    position: relative;
    .Header1 {
      display: flex;
      .Header1Box {
        padding: 20px 30px;
        .Header1BoxHedaer {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          h1,
          a {
            white-space: nowrap;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            color: #ffffff;
            margin-right: 10px;
            padding-bottom: 0px;
            border-bottom: 2px solid #ffffff;
          }
          img {
            width: 20px;
            margin-bottom: 0px;
            filter: invert(1);
          }
        }
        .Header1BoxMenu {
          display: flex;
          flex-wrap: wrap;
          // flex-direction: column;
          transition: 0.3s all;
          visibility: hidden;
          opacity: 0.1;
          position: absolute;
          top: 80px;
          left: 0%;
          background-color: white;
          clip-path: inset(0px -10px -10px -10px);
          box-shadow: 0 0 15px #f4f4fb6d;
          border: 1px solid #e9e9e988;
          padding: 10px 10px;
          width: calc(100% - 40%);
          margin-left: 20%;
          .Header1BoxMenuBox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            border-bottom: 1px solid #e9e9e988;
            padding: 15px 15px;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            white-space: nowrap;
            color: #2a1768;
          }
          .Header1BoxMenuBox:hover {
            background-color: #0156ff11;
            border-radius: 8px;
          }
          .Header1BoxMenuBox:last-child {
            border-bottom: none;
          }
        }
      }
      .Header1Box:hover {
        .Header1BoxHedaer {
          h1 {
            border-bottom: 2px solid #2a1768;
          }
        }
        .Header1BoxMenu {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    .Header2 {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 28px;
        border-radius: 3px;
        margin-right: 10px;
        filter: invert(0);
      }
      a {
        font-size: 25px;
        white-space: nowrap;
        font-weight: 500;
        color: #ffffff;

      }
    }
    .Header3 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      img {
        max-width: 25px;
        max-height: 25px;
        min-width: 25px;
        min-height: 25px;
        filter: invert(1);
        cursor: pointer;
      }
      .menuCloseBtn {
        max-width: 20px;
        max-height: 20px;
        min-width: 20px;
        min-height: 20px;
      }
      img:first-child {
        width: auto;
        filter: invert(1);
      }
    }
  }

  .HeaderTop {
    display: flex;
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    padding: 30px 5%;
    box-shadow: 0px 10px 15px -3px #0000001a;
    z-index: 1;
    background-color: #2a1768;


    .Header2 {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 28px;
        border-radius: 3px;
        margin-right: 10px;
        filter: invert(0);
      }
      a {
        font-size: 17px;
        white-space: nowrap;
        font-weight: 500;
        color: white;
      }
    }
    .Header3 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      img {
        max-width: 25px;
        max-height: 25px;
        min-width: 25px;
        min-height: 25px;
        filter: invert(0);
        cursor: pointer;
      }
      .menuCloseBtn {
        max-width: 20px;
        max-height: 20px;
        min-width: 20px;
        min-height: 20px;
      }
      img:first-child {
        width: auto;
        filter: invert(0);
      }
    }
  }
  .HeaderBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ECEEF6;
    box-shadow: 0px 10px 15px -3px #0000001a;
    width: 100%;
    height: 70px;
    padding: 20px 5%;
    .Header1Main {
      display: flex;
      .Header1 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        h2,
        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 17px;
          font-weight: 500;
          color: WHITE;
          cursor: pointer;
          background-color: #2a1768;
          border-radius: 3px;
          cursor: pointer;
          padding: 10px;
          padding-left: 20px;
          margin-right: 0px;

          img {
            // background-color: white;
            height: 20px;
            width: 20px;
            margin-left: 10px;
            filter: invert(1);
          }
        }
        h2:hover,
        a:hover {
          text-decoration: underline;
        }
      }
      .Header1:last-child {
        a {
          background-color: #DA291C;
          color: white;
          img {
            filter: invert(1);
          }
        }
      }
    }
    .Header2 {
      display: flex;
      align-items: center;
      justify-content: center;
      .Header2Box {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 20px;
        padding: 15px 0px;
        h2 {
          font-size: 20px;
          font-weight: 500;
        }
        img {
          width: 20px;
          margin-right: 5px;
        }
      }
      .Header2Box:hover {
        border-bottom: 3px solid #0000001a;
      }
    }
  }

  // Applications Toturial
  .ApplicationsToturial {
    display: flex;
    // display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background-color: #45454544;
    // backdrop-filter: blur(5px);
    .ApplicationsToturialShadow {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #45454544;
      // backdrop-filter: blur(5px);
      z-index: 1;
    }
    .ApplicationsToturialMain {
      width: 50%;
      max-width: 900px;
      height: auto;
      max-height: 90%;
      background-color: white;
      overflow: scroll;
      z-index: 2;
      padding: 50px 50px;
      border-radius: 10px;
      position: relative;
      animation: warningApply 0.4s ease-out;
      .ApplicationsToturialMainImgClose {
        display: flex;
        align-items: flex-end;
        justify-content: end;
        position: sticky;
        top: 0px;
        right: 0px;

        img {
          transition: 0.3s;
          cursor: pointer;
          width: 30px;
          padding: 5px;
          background-color: #d3d3d33f;
          backdrop-filter: blur(5px);
          border-radius: 50%;
          box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
        }
        img:hover {
          background-color: #d3d3d3b0;
        }
      }
      .ApplicationsToturialMainHeader {
        width: 100%;
        h3 {
          color: #2a1768;
          font-size: 25px;
          font-weight: 600;
          margin-top: 0px;
        }
        h4 {
          color: #082538;
          font-size: 16px;
          font-weight: 300;
          margin-top: 15px;
        }
      }
      .ApplicationsToturialMainBody {
        width: 100%;
        height: auto;
        padding-top: 30px;
        .ApplicationsToturialMainBodyBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 0px;
          width: 100%;
          border-bottom: 1px solid #e9e9e9;
          padding: 28px 0px;
          .ApplicationsToturialMainBodyBoxLeft {
            display: flex;
            align-items: center;
            justify-content: start;
            width: 20%;
            img {
              padding: 10px;
              max-width: 90px;
              width: 80%;
              background-color: #d3d3d33f;
              backdrop-filter: blur(5px);
              border-radius: 10px;
              box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
            }
          }
          .ApplicationsToturialMainBodyBoxRight {
            width: 80%;
            padding-left: 10px;
            h3 {
              color: #082538;
              font-size: 20px;
              font-weight: 500;
            }
            h4 {
              color: #082538;
              font-size: 15px;
              font-weight: 300;
              margin-top: 10px;
            }
            h5 {
              display: inline-block;
              border-left: 2px solid #0cff0cdd;
              color: #003a00dd;
              font-size: 15px;
              font-weight: 400;
              background-color: #0cff0c61;
              padding: 5px 15px;
              margin-top: 10px;
              border-radius: 0px 3px 3px 0px;
            }
          }
        }
        .ApplicationsToturialMainBodyBox:nth-child(4) {
          border-bottom: none;
        }
      }
      //
      .ApplicationsToturialMainBodyClose {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 50px;
        border-radius: 40px;
        background-color: #efefef;
        margin-top: 40px;
        cursor: pointer;
        transition: 0.3s;
        position: sticky;
        bottom: 0px;
        left: 0px;

        h1 {
          color: #082538;
          font-size: 20px;
          font-weight: 600;
        }
      }
      .ApplicationsToturialMainBodyClose:hover {
        background-color: #d3d3d3;
      }
    }
  }
}

@keyframes warningApply {
  0% {
    bottom: (-100%);
    opacity: 50%;
  }
  100% {
    bottom: (0);
    opacity: (100%);
  }
}
@media (max-width: 850px) {
  .Header {
    // padding: 8px 0%;
    // max-height: 55px;
    .HeaderTopComputer {
      display: none;
    }
    .HeaderTop {
      display: flex;
      height: 40px;
      padding: 25px 5%;
      .Header2 {
        h1 {
          font-size: 17px;
        }
      }
    }
    .HeaderBottom {
      height: 35px;
      padding: 25px 2%;
      .Header2 {
        .Header2Box {
          display: none;
          h2 {
            font-size: 14px;
          }
        }
      }
      .Header1Main {
        width: 100%;
        justify-content: space-between;
        gap: 10px;
        padding: 15px 0px;
        height: 100%;
        .Header1 {
          margin-left: 0px;
          width: 100%;
          h2,
          a {
            justify-content: center;
            padding: 10px 5px;
            border-radius: 3px;
            font-size: 14px;
            width: 100%;
            img {
              // display: none;
            }
          }
        }
      }
    }

  }
}
@media screen and (min-width: 1700px) {
  .Header {
    // padding-right: 13%;
    // padding-left: 13%;
    .HeaderTop,
    .HeaderBottom,
    .HeaderTopComputer {
      padding: 30px 13%;
    }
  }
}
